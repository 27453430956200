import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import {
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
} from "react-router-dom";
import "./App.css";

import MainPage from "./components/MainPage";
import ProjectPage from "./components/ProjectPage";

const TRACKING_ID = "G-9FKGVMQ7HK";
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: window.location.pathname });
ReactGA.event({
	category: "Portfolio",
	action: "HomePage",
});

function App() {
	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
	}, []);

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<MainPage />} />
					<Route path="/Project/:ProjectID" element={<ProjectPage />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
